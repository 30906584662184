import { default as indexJH7CyCzyOSMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/about-company/index.vue?macro=true";
import { default as _91slug_93RFY1kMBFmLMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/actions/[slug].vue?macro=true";
import { default as indexQfraUTM5IRMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/actions/index.vue?macro=true";
import { default as _91slug_930AiX5WCcImMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/blog/[slug].vue?macro=true";
import { default as indexPc8W7UzVMpMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/blog/index.vue?macro=true";
import { default as _91slug_93HRklzBmORVMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/bluda/[slug].vue?macro=true";
import { default as indexWoOyn0N1UAMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/bluda/index.vue?macro=true";
import { default as status1BlYG4Cn37Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/cabinet/payment/status.vue?macro=true";
import { default as index7YQH75E3o3Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/cart/index.vue?macro=true";
import { default as successRnPE6Mx2rAMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/cart/success.vue?macro=true";
import { default as _91slug_93LdGNdjlOAAMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/categories/[slug].vue?macro=true";
import { default as indexgLVGiUEbkzMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/categories/index.vue?macro=true";
import { default as checking_partners1hStVGEfAjMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/checking_partners.vue?macro=true";
import { default as contactsQV39qaM4n9Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/contacts.vue?macro=true";
import { default as indexddzcbwWWt3Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/delete-account/index.vue?macro=true";
import { default as index3cc8fI5aofMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/index.vue?macro=true";
import { default as index5Bw4gCdSRCMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/info-for-partners/index.vue?macro=true";
import { default as _91slug_93a5HH8Ppv2hMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/kuhni/[slug].vue?macro=true";
import { default as index9XJGNKmKoWMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/kuhni/index.vue?macro=true";
import { default as offerc2OZVypFyWMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/offer.vue?macro=true";
import { default as index0eE0YQxREHMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/partner-apply-from-mobile/index.vue?macro=true";
import { default as privacyIqBFncbssNMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/privacy.vue?macro=true";
import { default as addresesslRkLfuDSHMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/addreses.vue?macro=true";
import { default as indexFDiBd7r9QcMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/index.vue?macro=true";
import { default as infoaSPoMyVy7LMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/info.vue?macro=true";
import { default as reviewsTch6Hz2vBlMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/reviews.vue?macro=true";
import { default as _91slug_93x6Y32JkI37Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/restaurants/[slug].vue?macro=true";
import { default as indexGs9GzfqUiHMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/restaurants/index.vue?macro=true";
import { default as searchAgnK8F9bJ2Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/search.vue?macro=true";
import { default as standarts_partnersKwGBzzLpqRMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/standarts_partners.vue?macro=true";
import { default as terms_of_useU4ve589Nw1Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/terms_of_use.vue?macro=true";
import { default as index8hVDpLP4QvMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/wish/index.vue?macro=true";
export default [
  {
    name: "about-company",
    path: "/about-company",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/about-company/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-slug",
    path: "/actions/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/actions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "bluda-slug",
    path: "/bluda/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/bluda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bluda",
    path: "/bluda",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/bluda/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-payment-status",
    path: "/cabinet/payment/status",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/cabinet/payment/status.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: index7YQH75E3o3Meta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-success",
    path: "/cart/success",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "checking_partners",
    path: "/checking_partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/checking_partners.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-for-partners",
    path: "/info-for-partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/info-for-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "kuhni-slug",
    path: "/kuhni/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/kuhni/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kuhni",
    path: "/kuhni",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/kuhni/index.vue").then(m => m.default || m)
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: "partner-apply-from-mobile",
    path: "/partner-apply-from-mobile",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/partner-apply-from-mobile/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-addreses",
    path: "/profile/addreses",
    meta: addresesslRkLfuDSHMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/addreses.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexFDiBd7r9QcMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-info",
    path: "/profile/info",
    meta: infoaSPoMyVy7LMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-reviews",
    path: "/profile/reviews",
    meta: reviewsTch6Hz2vBlMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/profile/reviews.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug()",
    meta: _91slug_93x6Y32JkI37Meta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/restaurants/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "standarts_partners",
    path: "/standarts_partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/standarts_partners.vue").then(m => m.default || m)
  },
  {
    name: "terms_of_use",
    path: "/terms_of_use",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/terms_of_use.vue").then(m => m.default || m)
  },
  {
    name: "wish",
    path: "/wish",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20240918120014/pages/wish/index.vue").then(m => m.default || m)
  }
]